<template>
  <b-card no-body>
    <b-card-body>
      <div class="text-right">
        <b-button
          variant="primary"
          :to="$route.path + '/add'"
        >
          <FeatherIcon icon="PlusIcon" /> Oluştur
        </b-button>
      </div>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <div class="d-flex align-items-center">
          <div class="width-100">
            <b-img
              :src="baseURL + '/media/estates/' + data.item.image"
              fluid
              rounded
            />
          </div>
          <div class="ml-1">
            <div class="font-weight-bold">
              {{ data.item.title }}
            </div>
            <div>
              <b-badge
                v-if="data.item.is_home === '1' && data.item.status === '1'"
                variant="light-warning"
                class="mr-1"
              >
                <FeatherIcon icon="HomeIcon" />
                Anasayfa
              </b-badge>
              <b-badge
                :variant="data.item.status === '1'? 'light-success' : 'light-danger'"
              >
                <FeatherIcon
                  :icon="data.item.status === '1'? 'CheckCircleIcon' : 'XCircleIcon'"
                />
                {{ data.item.status === '1'? 'Yayında' : 'Yayında Değil' }}
              </b-badge>
            </div>
          </div>
        </div>
      </template>
      <template #cell(ordering)="data">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-danger"
              size="sm"
              @click="ordering('up',data.item)"
            >
              <FeatherIcon icon="ArrowUpIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="data.item.ordering"
            placeholder="Sıra No"
            size="sm"
            class="text-center"
            readonly
          />
          <b-input-group-append>
            <b-button
              variant="outline-danger"
              size="sm"
              @click="ordering('down',data.item)"
            >
              <FeatherIcon icon="ArrowDownIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template #cell(status)="data">
        <div>
          <b-badge
            v-if="data.item.is_rent === '1'"
            variant="primary"
            class="mr-1"
          >
            Kiralık
          </b-badge>
          <b-badge
            v-if="data.item.is_sale === '1'"
            variant="primary"
          >
            Satılık
          </b-badge>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <list-buttons
            :edit-action="$route.path + '/edit/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BBadge, BImg, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'estates'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BBadge,
    BImg,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ListButtons,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'ordering',
          label: 'SIRA NO',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'segment',
          label: 'SEGMENT',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'status',
          label: 'DURUM',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.is_rent AS is_rent`,
          `${tableName}.is_sale AS is_sale`,
          `${tableName}.is_home AS is_home`,
          `${tableName}.status AS status`,
          `${tableName}.ordering AS ordering`,
          'segments.title AS segment',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['estates/dataList']
    },
    dataCounts() {
      return this.$store.getters['estates/dataCounts']
    },
    saveData() {
      return this.$store.getters['estates/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('estates/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('estates/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
    ordering(type, data) {
      let ordering = Number(data.ordering)
      if (type === 'up') {
        ordering = Number(data.ordering) - 1
      } else if (type === 'down') {
        ordering = Number(data.ordering) + 1
      }
      this.$store.dispatch('estates/ordering', {
        ordering,
        id: data.id,
      })
    },
  },
}
</script>
